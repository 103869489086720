@import "config";
@import "compass/css3";
@import "compass/utilities";

$ancho-burger: 35px;
$alto-burger: 5px;

@media (max-width: 480px){
  div.carrusel-prompt {
    display: block !important;
  }
}
header.cabecera{
  margin-top: 74px;
}

.my-snap{
  position: absolute;
  background: #fff;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: auto;
  height: auto;
  z-index: 2;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
body.mobile {
  .a-slide{
    background-position: center center !important;
  }
  div#globalWrapper{

      &.mobile{

          .container{
            margin-right: 0 !important;
            margin-left: 0 !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
            width: 100% !important;
          }

        /*  noticias */

          .lo-interior-noticias {

            div.site-descripcion{
              margin-top: -28px;
              border-top: 4px solid $azul-acs-medio;
              background: $azul-acs-claro;
              color: $blanco !important;
              hr{
                border-top: 6px solid $blanco;
              }

              &:after{
                top: 99%;
                border-top: solid 20px $azul-acs-claro;
                border-left: solid 20px transparent;
                border-right: solid 20px transparent;
              }

            }

          }// fin lo-interior-noticias


        .site-descripcion{

          line-height: 22px;

          &.static{
            border-top: 4px solid $azul-acs-medio;
            background: $azul-acs-claro;
            color: $blanco !important;
            hr{
              border-top: 6px solid $blanco;
            }

            &:after{
              top: 99%;
              border-top: solid 20px $azul-acs-claro;
              border-left: solid 20px transparent;
              border-right: solid 20px transparent;
            }

          }

        }// fin site-descripcion



        .interior-static{
          margin-top: 1.5em;
        }
        /*  fin noticias */

        /* actividades */
        .interior-contenido{

          .actividades-content{
            margin-bottom: 0;
          }

        }
        /* fin actividades */
        .interior{
          .site-content{
            margin-bottom: 0;
          }
        }

      }

  }// fin globalWrapper <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

  background: $blanco;

  div#header{

    padding: 0;
    min-height: 74px;
    max-height: 74px;
    position: fixed;
    top: 0;
    z-index: 999;
    background: $azul-acs-medio;
    border-top: 4px solid $azul-acs-claro;
    width: 100%;

    /* burger menu */
    a.burger {
      display: inline-block;
      width:18px;
      height:18px;
      cursor: pointer;
      text-decoration: none;
    }
    a.burger i {
      position: relative;
      display: inline-block;
      width: $ancho-burger;
      height: $alto-burger;
      color:$azul-acs-claro;
      font:bold 14px/.4 Helvetica;
      text-transform: uppercase;
      text-indent:-55px;
      background: $azul-acs-claro;
      transition:all .2s ease-out;
    }
    a.burger i::before, a.burger i::after {
      content:'';
      width: $ancho-burger;
      height: $alto-burger;
      background: $azul-acs-claro;
      position: absolute;
      left:0;
      transition:all .2s ease-out;
    }
    a.burger i::before {
      top: -11px;
    }
    a.burger i::after {
      bottom: -11px;
    }
/*
    a.burger:hover i::before {
      top: -20px;
    }
    a.burger:hover i::after {
      bottom: -20px;
    }
*/
    a.burger.active i {
      background: #fff;
    }
    a.burger.active i::before {
      top:0;
      -webkit-transform: rotateZ(45deg);
      -moz-transform: rotateZ(45deg);
      -ms-transform: rotateZ(45deg);
      -o-transform: rotateZ(45deg);
      transform: rotateZ(45deg);
    }
    a.burger.active i::after {
      bottom:0;
      -webkit-transform: rotateZ(-45deg);
      -moz-transform: rotateZ(-45deg);
      -ms-transform: rotateZ(-45deg);
      -o-transform: rotateZ(-45deg);
      transform: rotateZ(-45deg);
    }

    /* Button placement */
    a.burger {
      position: absolute;
      right:2%;
      margin-left:-9px;
      top:50%;
      margin-top:-9px;
    }

    /* old burger menu*/
    .box-shadow-menu:before {
      content: "";
      position: absolute;
      right: 1em;
      top: 2.5em;
      width: 5%;
      min-width: 44px;
      height: 12px;
      background: $azul-acs-claro;
      box-shadow: 0 1.40em 0 0  $azul-acs-claro, 0 2.8em 0 0  $azul-acs-claro;
    }

    .logo-head {
      position: absolute;
      top: -4px;
      left: 15px;
      display: inline-block;
      img {
        max-width: 50%;
        min-width: 50%;
      }
    }

  }


  .interior{

    .mobile-sep{
      clear: both;
      border-top: 1px solid $azul-acs-claro;
      max-width: 70%;
      margin: 0 auto;
    }

    .carrusel-prompt {
      position: absolute;
      z-index: 99;
      left: 0;
      right: 0;
      margin: 0 auto;
      text-align: center;
      color: #ffffff;
      padding: 10px 0;

      .prompt-wrap{
        max-width: 90%;
        margin: 0 auto;
      }

    }

    .programa-descripcion{
      margin-top: -28px ;
      border-top: 4px solid $azul-acs-medio;
    }

    .programa-desc{
      width: 95%;
    }

    ul.mobile-programa-menu{

      li{
        width: 100%;
        padding: 3% 0;
        text-align: center;
        clear: both;
        border-bottom: 1px solid $azul-acs-claro;

        &.active{
          a{
            color: $azul-acs-oscuro !important;
          }
        }
        a{
          color: $azul-acs-medio !important;
          font-weight: 500;
          font-size: large;

        }

        .programas-link-wrap{

          max-width: 80%;
          margin: 0 auto;
          position: relative;

          &:after{
            content: ' > ' ;
            float: right;
            position: relative;
            top: 8%;
            font-size: large;
            right: -15px;
            position: absolute;
            color: $azul-acs-medio;
          }

        }

      }
      margin-bottom: 2em;

    }

  }// fin .interior

  .userBox {
    .col-md-4, .col-md-2, .col-md-6 {
      width: 100% !important;
    }
  }

  div#globalWrapper {
    overflow-x: hidden;
  }

  #contentPjax {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    z-index: 2;
    overflow: auto;
    background: $blanco;
    margin-top: 150px;

    .userBoxAlert {
      display: none;
      width: 100%;
      color: white;
      padding: 16px 0;
      text-align: center;
      position: absolute;
      top: 0;
      z-index:9999;

      &::after {
        content: "";
        background: #000;
        opacity: 0.85;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: -1;
      }
    }

  }

  .snap-drawers {
    position: absolute;
    top: 75px;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    background: $blanco;
    z-index: 0;
  }


  &.snapjs-left .snap-drawer-right, &.snapjs-right .snap-drawer-left {
    display: none;
  }

  .snap-drawer {
    position: fixed;
    top: 75px;
    right: auto;
    bottom: 0;
    left: auto;
    width: 265px;
    height: auto;
    overflow: auto;
    transition: width 0.3s ease;

    &.snap-drawer-left {
      left: 0;
      z-index: 1;
      border-right: 1px solid $azul-acs-claro;
    }

    &.snap-drawer-right {
      right: 0;
      z-index: 1;
      border-left: 1px solid $azul-acs-claro;
    }

    ul {
      margin: 0;
      padding:0;

      li {
        border-top: 1px solid $azul-acs-claro;
        border-bottom: 1px solid $azul-acs-claro ;
        margin: 0;
        padding:16px;
        color: $azul-acs-medio;
        &:first-child {
          border-top:none;
        }
        &:last-child {
          border-bottom: none;
        }
        &.active{
          a{
            font-weight: bolder;
            color: $azul-acs-oscuro;
          }
        }
        a {
          color:$azul-acs-medio;
        }


        // los items del final, copyright, mapa web etc
        &.menu-bottom{

            color: $blanco;
            background: $azul-acs-oscuro;

            &.active{
              a{
                font-weight: bolder;
                color: $blanco;
              }
            }
            a {
              color: $blanco;
            }

        }// fin menu-bottom

        // menus drop down en mobile
        ul{
          li{
            border-top: 0px solid $azul-acs-claro;
            border-bottom: 0px solid $azul-acs-claro ;
            margin: 0;
            padding: 9px;

            &.active{
              a{
                font-weight: bolder;
                color: $azul-acs-oscuro;
              }
            }
            // menu dropdown de programas en mobile
            &.dropProgramas{
              color: #000;
              ul{
                text-align: left !important;
              }
            }
            // fin menus drop down de mobile
          }

        }

      }
    }

  }
  div.login-box, div.user-box{
    color: #fff;
    a{
      color: $rojo-claro;
    }
    h3{
      font-size: 16px;
      font-weight: bold;
      margin: 2% 0 4% 0;
    }
    .form-group{
      margin-bottom: 2%;
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
    }
    input[type=text],input[type=password],input[type=email]{
      width: 100%;
      @include background-image(linear-gradient(to bottom, #fff, #f5f5f5));
      border: solid 1px #d4d4d4;
      padding: 6px 5px;
      color: #000;
    }
    .recordar{
      label{
        color: #fff;
        font-size: 12px;
        display: inline-block;
        vertical-align: middle;
      }
      input[type=checkbox]{
        padding-top: 4px;
        display: inline-block;
        vertical-align: middle;
      }
    }
    .btn{
      background: $rojo-oscuro;
      color: #fff;
      padding: 3px 6px;
      border-width: 0;
      transition: background-color 0.5s ease;
      &:after{
        content: "\203A";
        margin-left: 4px;
        font-size: 18px;
      }
      &:hover{
        background: $rojo-claro;
      }
    }
    .help-block{
      color: $rojo-claro;
      font-size: 12px;
    }
    .login-error{
      clear: both;
      color: $rojo-claro;
      margin: 1% 0;
      font-size: 12px;
    }
    .registro{
      margin: 10% 0 0 0;
      font-size: 12px;
    }
  }
  .recuperar-clave-box{
    display: none;
    &.activo{
      display: block;
    }
    .msg-resultado{
      &.error{
        color: $rojo-claro;
      }
      margin: 2% 0;
      font-size: 12px;
    }
  }

  div.social, div.confianza {
    padding: 14px;
  }
}

// paginacion de noticias detalle
div.noticias-pager{

  &.detalle{

    ul.pagination{
      overflow: hidden;
      text-align: center !important;
      li{

        &.prev{
          left: 0;
          position: relative !important;
          top:0 !important;
          z-index: 998;
        }
        &.next{
          right: 0;
          position: relative !important;
          top:0 !important;
          z-index: 998;
        }
        span, a{
          border: none;
          color: $azul-acs-claro;
          background-color: transparent;
          text-transform: capitalize;
          .lt,.gt{
            color: $azul-acs-medio !important;
            font-size: 20px;
          }
          &:hover{
            background: none !important;
            color: $azul-acs-oscuro !important;
            font-weight: 700;
          }
        }

      }
    }
  }

}// fin noticias-pager

#idioma-widget{

  overflow:hidden;
  padding: 0;

  /* widget de idiomas */
  ul.list-lang{

    font-size: 12px;
    padding-top: 0;
    margin: 0;
    width: 100%;

    span.actual-lang{
      color: $blanco;
    }

    > li{
      margin: 0;
      text-align: left;
      background: $azul-acs-claro;
      padding-left: 10px;
      padding-top: 15px;
      padding-bottom: 15px;

      button.btn-language{
        border: 0;
        outline: none;
        background: none;
        color: $azul-acs-medio;
      }

      &[data-actual="false"]{
        // border-top: 1px solid $blanco;
      }
      &[data-actual="true"]{
        position: relative;
        padding-left: 30px;
        &:before{
            content: ' ';
            background-image: url($image-path + 'mobile/flechita-right.png');
            height: 18px;
            width: 8px;
            color: $blanco;
            position: absolute;
            left: 15px;
            bottom: 13px;
        }
      }

      &:nth-child(n+2){
        border-top: 1px solid $blanco;
        :before{
          content: '';
          position: relative;
          right: 0;
          height: 0;
          background: $azul-acs-medio;
          color: $azul-acs-medio;
        }
      }

    }// fin li

  }
  /* FIN widget de idiomas */

}


@media (min-width: 768px) and (max-width: 991px) {

}

@media (min-width: 992px) and (max-width: 1199px) {

}

@media (min-width: 1200px) {

}

@media (max-width: 1025px) {

  body {

    div#contentPjax {
      padding-bottom: 0em !important;
    }

    div#header {
      padding: 0;
      min-height: 74px;
      max-height: 74px;
      position: fixed;
      top: 0;
      z-index: 999;
      background: $azul-acs-medio;
      border-top: 4px solid $azul-acs-claro;
      width: 100%;

      a#menu-open {
        //float: left;
        background: url('/www/images/menu-open.png') no-repeat center center;
        width: 44px;
        height: 44px;
      }

      h2 {
        /*
        width: 140px;
        margin: 0 auto;
        text-align: center;
       */
      }

      .logo-head {
        position: absolute;
        top: -4px;
        left: 15px;
        display: inline-block;
        img {
          max-width: 50%;
          min-width: 50%;
        }
      }
      #btn-login {
        margin: 1% 1% 0 0;
      }

    }

    div.barra {
      position: fixed;
      top: 0;
      margin-top: 44px;
      width: 100%;
      z-index: 999;

      div.container {
        padding: 0;
      }

      div.buscador {
        width: 0;

      }

      div.botones {
        margin-right: 4px;

      }

    }

    div#globalWrapper div#contentPjax {
      margin-top: 150px;
    }

    div.userBox {

      > .container {
        width: 100%;
      }

      display: block;
      padding: 30px 0;
      border-top: none;

      .aspa {
        display: none;
      }

      &.alert {
        display: block;
        div.userBoxContent {
          display: block;
        }
      }

      .userBoxAlert {
        display: none;
        text-align: center;
      }

      div.userBoxContent {

        font-size: .9em;

        div#loginFormDiv {
          display: block;
          &.inactivo {
            display: none;
          }
        }

        div#recordarFormDiv, div#registroFormDiv {
          display: none;
          &.activo {
            display: block;
          }
        }

        h3 {
          //font-size: 1em;
        }

        form {

          .form-group {
            label {
              //font-size:.8em;
              margin: 5px 0;
            }
            input:not([type='checkbox']):not([type='radio']) {
              //font-size:.8em;
              height: 25px;
            }
          }

          div.row {

          }

          .userBoxContentAlert {

          }

          .help-block-error {

          }

        }

        a {

          &:hover {

          }
        }

      }

    }

    .page-content {

      padding-bottom: 1em !important;
      .cabecera {

        height: auto !important;
        background: url('/www/images/cabeceras/registro-mobile.jpg') no-repeat !important;
        background-size: cover !important;

        h1 {
          line-height: 1em !important;
          font-size:1.3em !important;
          margin: .6em 0 !important;
        }
      }
    }

  }

}
.listado-publicaciones{
  .lv-publicacion-item{
    flex-wrap: wrap;
  }
  .publicacion-lv-wrap{
    height: auto;
    min-height: auto !important;
    padding: 2px !important;
  }
  .publicacion-img{

  }
  .publicacion-item{
    flex: 1 1 auto;
    width: 50% !important;
    max-width: 50% !important;
    min-height: auto !important;
    margin: 0 !important;
    .img-responsive{
      max-width: 50px;
    }
  }
}
.publicaciones-pager{
  margin-bottom: 0 !important;
  padding-bottom: 50px;
}
.snap-flex{
  display: flex;
  flex-flow: column;
  height: 100%;
  .snap-flex-col{
    flex: 0 1 auto;
  }
}
.titulos-cabecera{
  position: absolute;
  bottom: 10px;
  padding: 0 20px;
  width: 100%;
  .titulo{
    font-size: 32px;
    font-weight: 900;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 1px 1px rgba(0,0,0,0.80);
  }
  .contenido-titulo{
    font-size: 22px;
    font-weight: 400;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 1px 1px rgba(0,0,0,0.80);
  }
  &.portada{
    bottom: -3px;
    left: 0;
    .titulo{
      text-align: center;
    }
    .logo-acs{
      width: 90%;
      margin: 0 auto;
      display: block;
    }
  }
}
